import { useState, KeyboardEvent, useMemo, useRef, useEffect } from 'react';
import { Flexbox, Snackbar, TextArea, CustomTyphography, ConfirmationDialog, ActionsMenu, Loader, IconButton, Tooltip, CommonButton, Dialog } from 'components';
import classNames from 'classnames/bind';
import styles from './assistant.module.scss';
import { ChatIcon, CloseIcon, DeleteIcon, EditIcon, Logo, MenuOpenIcon, PlusCircle, SendIcon } from 'components/icons';
import UserLogo from 'common/UserLogo';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserSession, getUserLatestSession, getUserSession, getUserSessions, renameUserSession, sendMessage } from './assistant.api';
import { AIStreamingEndCharCode } from 'utils/constants';
import { userSelector } from 'store/user';
import { v4 as uuid } from 'uuid';
import { Message, currentSessionLoadingSelector, deleteSession, messagesSelector, renameChat, sessionIdSelector, sessionsSelector, setMessages, setSessionId } from 'store/assistant-slice';
import { setToLocalStorage } from 'utils/localStorage';
import CommonInput from 'components/CommonInput';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw'

const classes = classNames.bind(styles);

export default () => {
    const dispatch = useDispatch();

    const [question, setQuestion] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [assistantStatus, setAssistantStatus] = useState<null | string>(null);
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [openHistorySidebar, setOpenHistorySidebar] = useState(false);
    const [currentSessionId, setCurrentSessionId] = useState<null | string>(null);
    const [openRenameDialog, setOpenRenameDialog] = useState(false);
    const [chatTitle, setChatTitle] = useState('');

    const [error, setError] = useState(false);
    const [streamedText, setStreamedText] = useState('');

    const user = useSelector(userSelector);
    const sessionId = useSelector(sessionIdSelector);
    const messages = useSelector(messagesSelector);
    const sessions = useSelector(sessionsSelector);
    const currentSessionLoading = useSelector(currentSessionLoadingSelector);

    useEffect(() => {
        if (!sessionId) {
            const newSessionId = uuid();
            dispatch(setSessionId(newSessionId))
        }
    }, [sessionId]);

    useEffect(() => {
        dispatch(getUserLatestSession(user.id));
        dispatch(getUserSessions(user.id))
    }, [])

    const onKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            onSendMessage();
        }
    };

    const chatContainerRef = useRef<HTMLDivElement>(null);

    const onSendMessage = async () => {
        if (question && sessionId) {
            let generatedText = '';

            const newMessages: Message[] = [{ from: 'user', text: question }, ...messages];
            dispatch(setMessages(newMessages));
            setQuestion('');
            setIsLoading(true)

            dispatch(
                sendMessage(
                    question,
                    sessionId,
                    (value: any, type?: 'log' | 'response') => {
                        if (type === 'log') {
                            setAssistantStatus(value as string)
                        } else {
                            setAssistantStatus(null);
                            let text = '';

                            if (value.type === 'text') {
                                text = value.content;
                            } else if (value.type === 'prd_preview') {
                                text = Object.values(value.content).join('\n')
                            }
                            generatedText = (generatedText + text).replace('```html', '')
                            setStreamedText(generatedText);

                            if (text.charCodeAt(0) === AIStreamingEndCharCode) {
                                setIsLoading(false)
                                const updatedMessages: Message[] = [{ from: 'assistant', text: generatedText.trim().replace('```', '') }, ...newMessages];
                                dispatch(setMessages(updatedMessages));
                                setStreamedText('');
                                return;
                            }
                        }
                    },
                    () => {
                        setStreamedText('');
                        setIsLoading(false)
                        setError(true)
                    }
                )
            );
        }
    };

    useEffect(() => {
        if (sessionId) {
            setToLocalStorage(sessionId, sessionId);
        }
    }, [sessionId])

    const handleDeleteChat = async () => {
        if (currentSessionId) {
            try {
                await dispatch(deleteUserSession({ userId: user.id, sessionId: currentSessionId }))
                dispatch(deleteSession(currentSessionId));

                if (sessionId === currentSessionId) {
                    handleStartNewSession()
                }
            } catch (error) {
                console.log(error);
            }
        }
        setOpenDeleteConfirmation(false)
        setCurrentSessionId(null)
    }

    const showDeleteConfirmation = (sessionId: string) => {
        setCurrentSessionId(sessionId)
        setOpenDeleteConfirmation(true)
    }

    const onCancelDelete = () => {
        setOpenDeleteConfirmation(false)
    }

    const handleSelectSession = (sessionId: string) => {
        try {
            dispatch(getUserSession({ userId: user.id, sessionId: sessionId }))
        } catch (error) {
            console.log(error);
        }
    }

    const handleStartNewSession = () => {
        const newSessionId = uuid();
        dispatch(setSessionId(newSessionId))
        dispatch(setMessages([]))
    }

    const resetRenameDialog = () => {
        setOpenRenameDialog(false)
        setCurrentSessionId(null);
        setChatTitle('')
    }

    const handleRenameChat = async () => {
        if (currentSessionId) {
            try {
                await dispatch(renameUserSession({ userId: user.id, sessionId: currentSessionId, data: { title: chatTitle } }));
                dispatch(renameChat({ id: currentSessionId, title: chatTitle }))
            } catch (err) {
                console.log(err);
            }
        }

        resetRenameDialog()
    }

    useEffect(() => {
        if (sessions.every(session => session.session_id !== sessionId) && messages.length >= 2) {
            dispatch(getUserSessions(user.id))
        }
    }, [messages.length, sessionId, sessions.length])

    useEffect(() => {
        if (chatContainerRef.current && streamedText) {
            chatContainerRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
        }
    }, [streamedText, messages]);

    return (
        <Flexbox fullWidth className={classes('assistantContainer')}>
            {
                openHistorySidebar && (
                    <Flexbox vertical className={classes('history', 'gap-3')}>
                        <CommonButton
                            buttonType='shadow'
                            variant='text'
                            startIcon={<PlusCircle />}
                            onClick={handleStartNewSession}
                        >
                            New Session
                        </CommonButton>
                        {
                            sessions.map(session => {
                                return (
                                    <Flexbox
                                        justifyBetween
                                        key={session.session_id}
                                        className={classes('history-item', { active: session.session_id === sessionId })}
                                        onClick={() => handleSelectSession(session.session_id)}
                                    >
                                        <CustomTyphography className={classes('ellipsisText')}>{session.title}</CustomTyphography>
                                        <ActionsMenu className={classes('sessionActions-btn')}
                                            buttonItems={[
                                                {
                                                    label: 'Rename chat',
                                                    action: () => {
                                                        setOpenRenameDialog(true);
                                                        setCurrentSessionId(session.session_id)
                                                        setChatTitle(session.title)
                                                    },
                                                    icon: <EditIcon />
                                                },
                                                { label: 'Delete chat', action: () => showDeleteConfirmation(session.session_id), icon: <DeleteIcon />, type: 'red' },
                                            ]}
                                        />
                                    </Flexbox>
                                )
                            })
                        }
                    </Flexbox>
                )
            }
            <Flexbox className={classes('messengerContainer')} fullWidth vertical ref={chatContainerRef}>
                <Flexbox className={classes('messagesContainer')} fullWidth>
                    {currentSessionLoading ? <Flexbox justify fullWidth className={classes('p-4')}><Loader /></Flexbox> : <>
                        {
                            (!!assistantStatus || isLoading) && (
                                <Flexbox className={classes('messageContainer', { fromAI: streamedText.length })}>
                                    <Flexbox className={classes('logoContainer')}>
                                        <Logo />
                                    </Flexbox>
                                    <Flexbox>
                                        {streamedText.length ? (
                                            <Flexbox
                                                fullWidth
                                                vertical
                                            >
                                                <Markdown remarkPlugins={[remarkGfm]}>{streamedText}</Markdown>
                                            </Flexbox>
                                        ) : (
                                            <Flexbox className={classes('gap-1')}>
                                                {assistantStatus &&
                                                    <Flexbox align className={classes('assistantStatus')}>
                                                        <CustomTyphography className={classes('assistantStatus-message')}>{assistantStatus}</CustomTyphography>
                                                    </Flexbox>
                                                }
                                                <Flexbox align className={classes('dotFlashingContainer')}>
                                                    <Flexbox className={classes('dotFlashing')} />
                                                </Flexbox>
                                            </Flexbox>
                                        )}
                                    </Flexbox>
                                </Flexbox>
                            )
                        }

                        {messages.map((message, index) => (
                            <Flexbox
                                key={index}
                                className={classes('messageContainer', { fromAI: message.from === 'assistant' })}
                            >
                                <Flexbox className={classes('logoContainer')}>
                                    {message.from === 'user' ? <UserLogo /> : <Logo />}
                                </Flexbox>
                                {message.from === 'user' ? (
                                    <Flexbox className={classes('message')}>{message.text}</Flexbox>
                                ) : (
                                    <Flexbox
                                        fullWidth
                                        vertical
                                    >
                                        <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>{message.text.replaceAll('```html', '').replaceAll('```', '')}</Markdown>
                                    </Flexbox>
                                )}
                            </Flexbox>
                        ))}
                    </>
                    }
                </Flexbox>

                <Flexbox className={classes('newMessageContainer')} fullWidth>
                    <Flexbox align className={classes('gap-1')}>
                        <Tooltip title={`${openHistorySidebar ? 'Close' : 'Open'} Chat History`}>
                            <IconButton
                                className={classes('action-btn')}
                                disabled={!sessions.length}
                                onClick={() => setOpenHistorySidebar(prev => !prev)}
                            >
                                {openHistorySidebar ? <CloseIcon /> : <MenuOpenIcon />}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'New Chat'}>
                            <IconButton
                                className={classes('action-btn')}
                                onClick={handleStartNewSession}
                            >
                                <ChatIcon />
                            </IconButton>
                        </Tooltip>
                    </Flexbox>

                    <TextArea
                        value={question}
                        onKeyDown={onKeyPress}
                        onChange={e => {
                            setQuestion(e.target.value);
                        }}
                        placeholder="Ask here"
                        className={classes('textArea')}
                        fullWidth
                        endAdornment={
                            <SendIcon className={classes('sendIcon')} onClick={onSendMessage} />
                        }
                        autoFocus
                        disabled={isLoading}
                    />
                </Flexbox>
                <ConfirmationDialog
                    open={openDeleteConfirmation}
                    onClose={onCancelDelete}
                    onConfirm={handleDeleteChat}
                    confirmButtonStyle='danger'
                    title='Delete the history?'
                >
                    <Flexbox>
                        Are you sure you want to clear the chat history?
                    </Flexbox>
                </ConfirmationDialog>

                <Snackbar open={error} onClose={() => setError(false)} type="error">
                    <Flexbox>Sorry, An error occurred. Please try again later!</Flexbox>
                </Snackbar>
                <Snackbar open={error} onClose={() => setError(false)} type="error">
                    <Flexbox>Chat deleted successfully!</Flexbox>
                </Snackbar>
            </Flexbox>

            <Dialog
                onClose={resetRenameDialog}
                open={openRenameDialog}
                cancelButton
                title="Rename Chat"
                confirmButton
                onConfirm={handleRenameChat}
                disabled={!chatTitle}
                PaperProps={{
                    sx: {
                        width: '450px'
                    }
                }}
            >
                <Flexbox fullWidth>
                    <CommonInput value={chatTitle} onChange={(e) => setChatTitle(e.target.value)} />
                </Flexbox>
            </Dialog>
        </Flexbox>
    );
};
